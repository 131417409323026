import logo from './logo2.png'
import './App.css'
import appleLogo from './icons/apple.svg'
import windowsLogo from './icons/windows.svg'
import computer from './icons/computer-solid.svg'

function App () {
  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} alt="logo"/>
        <p>
          Ad-Hoc Support Client
        </p>
      </header>
      <div className="download-section">
        <div className="download-link">
          <a
            href="https://my.anydesk.com/v2/api/v2/custom-clients/downloads/public/UU9LLYHWCDRL/CTS-ADAgent.exe"
            aria-label="Download Windows Client">
            <img src={windowsLogo} className="download-logo"
                 alt="Windows Client Download"/>
            Download for Windows
          </a>
        </div>
        <div className="download-link">
          <a
            href="https://my.anydesk.com/v2/api/v2/custom-clients/downloads/public/CNZRNK5WR44C/CovenantADClient.dmg"
            aria-label="Download Apple Mac Client">
            <img src={appleLogo} className="download-logo"
                 alt="macOS Client Download"/>
            Download for macOS
          </a>
        </div>
      </div>
      <div className="tech-section">
        <div className="tech-download">
          <a
            href="https://my.anydesk.com/v2/api/v2/custom-clients/downloads/public/03GCIAUTEIY4/CTSTechBuild.exe">
            <img src={computer} className="tech-logo" alt="Technician Download"/>
            Technician Download
          </a>
        </div>
      </div>
    </div>
  )
}

export default App
